import { ParsedPhoneNumber, parsePhoneNumber } from 'awesome-phonenumber';
import { CountryCode } from 'common/constants/countries';

declare global {
  interface Window {
    hsIntl: any;
    hsMessages: any;
  }
}

type Option = {
  number: string;
  country: string;
};

export type LibPhoneNumberResult = {
  region: CountryCode;
  country: string;
  number: string;
};

const intl = window.hsIntl;
const messages = window.hsMessages;

// NOTE: IMPORTANT! If you remove or add a country to this list, make sure you
// also add its country code to
// HFUtils::$country_code_to_name_and_page_multiplier_map
const countryNamesByShortCode: {
  [countryCode in CountryCode]?: string;
} = {
  AD: 'Andorra',
  // "AL": "Albania",
  AG: 'Antigua And Barbuda',
  AI: 'Anguilla',
  AR: 'Argentina',
  AS: 'American Samoa',
  AT: 'Austria',
  AU: 'Australia',
  AX: 'Finland',
  // "BD": "Bangladesh",
  BE: 'Belgium',
  // "BG": "Bulgaria",
  BM: 'Bermuda',
  BN: 'Brunei Darussalam',
  BR: 'Brazil',
  BB: 'Barbados',
  BS: 'Bahamas',
  CA: 'Canada',
  CH: 'Switzerland',
  // "CL": "Chile",
  CN: 'China',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DK: 'Denmark',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  ES: 'Spain',
  FI: 'Finland',
  FR: 'France',
  GB: 'United Kingdom',
  GD: 'Grenada',
  JE: 'Jersey',
  IM: 'Isle of Man',
  GG: 'Guernsey',
  // "GE": "Georgia",
  // "GF": "French Guiana",
  GI: 'Gibraltar',
  GP: 'Guadeloupe',
  GR: 'Greece',
  GU: 'Guam',
  HK: 'Hong Kong',
  HR: 'Croatia',
  HU: 'Hungary',
  IE: 'Ireland',
  IL: 'Israel',
  IN: 'India',
  // "IQ": "Iraq",
  IS: 'Iceland',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  KH: 'Cambodia',
  KN: 'Saint Kitts And Nevis',
  KR: 'Korea',
  KY: 'Cayman Islands',
  LA: 'Laos',
  LC: 'Saint Lucia',
  LU: 'Luxembourg',
  // "LV": "Latvia",
  // "MA": "Morocco",
  // "MC": "Monaco",
  MO: 'Macao',
  MP: 'Northern Mariana Islands',
  // "MQ": "Martinique",
  // "MT": "Malta",
  MS: 'Montserrat',
  MY: 'Malaysia',
  NL: 'Netherlands',
  NO: 'Norway',
  NZ: 'New Zealand',
  PA: 'Panama',
  PE: 'Peru',
  // "PK": "Pakistan",
  PL: 'Poland',
  PR: 'Puerto Rico',
  PT: 'Portugal',
  PY: 'Paraguay',
  // "RE": "Reunion",
  RO: 'Romania',
  SE: 'Sweden',
  SG: 'Singapore',
  // SI: "Slovenia",
  SK: 'Slovakia',
  // "SM": "San Marino",
  TC: 'Turks And Caicos Islands',
  TH: 'Thailand',
  TR: 'Turkey',
  TT: 'Trinidad And Tobago',
  TW: 'Taiwan',
  US: 'United States',
  UY: 'Uruguay',
  VC: 'Saint Vincent And Grenadines',
  VG: 'Virgin Islands, British',
  VI: 'Virgin Islands, US',
  ZA: 'South Africa',
  AE: 'United Arab Emirates',
  SA: 'Saudi Arabia',
  SX: 'Sint Maarten',
  QA: 'Qatar',
  EG: 'Egypt',
  VA: 'Vatican City',
};

// Countries we prioritize the number matches for. Be sure to match the strings
// with the country names in countryNamesByShortCode
const priorityCountries = [
  'United States',
  'Canada',
  'United Kingdom',
  'Jersey',
  'Australia',
];

const cleanNumber = (number: string) =>
  number.replace(/\D/g, '').replace(/^0/, '').replace(/^\+/, '');

const getCountryNameByCountryShortCode = (countryShortCode: CountryCode) => {
  return countryNamesByShortCode[countryShortCode] || countryShortCode;
};

export const getFormattedPhoneOptions = (
  input: string,
  selectedRegion?: CountryCode,
  allowMexico = false,
) => {
  let results: LibPhoneNumberResult[] = [];
  let explicitInternationalCode = input[0] === '+';

  // prevent email addresses like 555-555-5555@gmail.com to be formatted as phone numbers
  if (input.indexOf('@') >= 0) {
    return [];
  }

  // remove unnecessary characters
  const number = cleanNumber(input);

  if (number.length < 5) {
    return results;
  }

  if (selectedRegion && selectedRegion.length > 0) {
    try {
      const regionNumber = parsePhoneNumber(number, {
        regionCode: selectedRegion,
      });

      if (regionNumber.number) {
        results.push({
          region: selectedRegion,
          country: getCountryNameByCountryShortCode(selectedRegion),
          number: regionNumber.number?.international,
        });
      }
    } catch (e) {
      /* do nothing */
    }
  } else {
    if (allowMexico) {
      countryNamesByShortCode.MX = 'Mexico';
    }

    // try parsing exact without regionCode first
    if (explicitInternationalCode) {
      try {
        // parse number and check if it's valid
        const regionNumber = parsePhoneNumber(input);
        if (regionNumber.valid && regionNumber.number) {
          const result = {
            region: regionNumber.regionCode as CountryCode,
            country: getCountryNameByCountryShortCode(
              regionNumber.regionCode as CountryCode,
            ),
            number: regionNumber.number.international,
          };
          results.unshift(result);
        }
      } catch (e) {
        /* do nothing */
      }
    } else {
      try {
        // parse number and check if it's valid
        const regionNumber = parsePhoneNumber(`+${input}`);
        if (regionNumber.valid && regionNumber.number) {
          const result = {
            region: regionNumber.regionCode as CountryCode,
            country: getCountryNameByCountryShortCode(
              regionNumber.regionCode as CountryCode,
            ),
            number: regionNumber.number.international,
          };
          explicitInternationalCode = true;
          results.unshift(result);
        }
      } catch (e) {
        /* do nothing */
      }
    }

    for (const countryCode in countryNamesByShortCode) {
      if (countryCode) {
        let regionNumber: ParsedPhoneNumber | null;
        // if not valid try without leading +
        try {
          regionNumber = parsePhoneNumber(number, { regionCode: countryCode });

          // push to results
          if (regionNumber && regionNumber.valid && regionNumber.number) {
            // parse the formatted number to validate the country code
            regionNumber = parsePhoneNumber(regionNumber.number.international);
            // check if it's already in result set
            if (
              regionNumber.number &&
              results.findIndex(
                (result) =>
                  regionNumber &&
                  regionNumber.number &&
                  result.number === regionNumber.number.international,
              ) === -1
            ) {
              const result = {
                region: regionNumber.regionCode as CountryCode,
                country: getCountryNameByCountryShortCode(
                  regionNumber.regionCode as CountryCode,
                ),
                number: regionNumber.number.international,
              };
              results.push(result);
            }
          }
        } catch (e) {
          /* do nothing */
        }
      }
    }
  }

  // Prepend priority countries when the user is NOT specifying a country code
  // explicity (using a plus)
  if (!explicitInternationalCode) {
    results = results
      .filter((number) => priorityCountries.indexOf(number.country) > -1)
      .sort(
        (a, b) =>
          priorityCountries.indexOf(a.country) -
          priorityCountries.indexOf(b.country),
      )
      .concat(results);
  }
  results = results.filter(
    (number, index, arr) =>
      index === arr.findIndex((result) => result.region === number.region),
  );
  // remove duplicate
  results = results.filter(
    (number, index, arr) =>
      index === arr.findIndex((result) => result.number === number.number),
  );
  return results;
};

export const formatInternationalFaxNumber = (
  number: string,
  options: Option[],
) => {
  // strip to digits
  const digits = number.replace(/\D/g, '');

  if (digits.toString().length < 5) {
    return number;
  }

  // loop through options looking for an exact match
  for (let i = 0; i < options.length; i++) {
    const n = options[i].number.replace(/\D/g, '');

    // if match, return
    if (n === digits) {
      // eslint-disable-next-line no-param-reassign
      number = options[i].number;
      break;
    }
  }

  // return originally passed number, no formatting option found
  return number;
};

export const updateDestinationOptions = (
  el: any,
  destination: any,
  options: Option[],
) => {
  // reset changed
  el.attr('changed', 'false');

  if (options.length >= 1) {
    el.val(options[0].number);
    destination
      .html(
        `<div class="destination_text">${options[0].country.toLowerCase()}</div>`,
      )
      .fadeIn();
  }

  if (options.length > 1) {
    destination.addClass('multiple_destinations');
    let destinationOptionsHtml = '';
    for (let i = 0; i < options.length; i++) {
      const destinationClass = `destination_option${i === 0 ? ' selected' : ''}`;

      if (i === 1) {
        // we keep the number and country the same as whatever is currently selected
        destinationOptionsHtml += `<div class="${destinationClass}"><a target="_blank" rel="noopener noreferrer" href="http://www.hellofax.com/info/internationalFaxPricing">${intl.formatMessage(messages.countriesSupported)}</a></div>`;
      }

      destinationOptionsHtml += `<div class="${destinationClass}" number="${options[i].number}" country="${options[i].country}">${options[i].number} - ${options[i].country.toLowerCase()}</div>`;
    }

    destination.append(destinationOptionsHtml);
  } else {
    destination.removeClass('multiple_destinations');
  }

  el.attr('changed', el.val());
};
