import React from 'react';

const NOOP = () => {};
type Setup = (el: HTMLDivElement) => void;

export default function useDiv(setup: Setup = NOOP) {
  const [domNode] = React.useState(() => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    setup(div);
    return div;
  });
  React.useEffect(() => {
    return function cleanup() {
      document.body.removeChild(domNode);
    };
  }, [domNode]);
  return domNode;
}

/**
 * useLazyDiv will only create a div on demand, and then will clean it up when
 * the hook unmounts.
 */
export function useLazyDiv(setup: Setup = NOOP) {
  const storedDiv = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    return function cleanup() {
      if (storedDiv) {
        // If this were holding a ref, it would be gone before the cleanup
        // function is called. Since I know I'm manging this myself it's safe
        // to:
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const div = storedDiv.current;
        if (div) {
          document.body.removeChild(div);
        }
      }
    };
  }, [storedDiv]);

  return () => {
    if (!storedDiv.current) {
      const div = document.createElement('div');
      storedDiv.current = div;
      document.body.appendChild(div);
      // div.id = `lazy ${Math.random()}`;
      setup(div);
    }

    return storedDiv.current!;
  };
}
