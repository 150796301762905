/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  import/no-commonjs,
  eqeqeq,
  func-names,
  max-len,
  no-param-reassign,
  no-plusplus,
  no-restricted-globals,
  no-var
*/
var TextUtils = function (app, textField, config) {
  if (!textField) {
    throw new Error('No text field provided');
  }

  config = config || {};

  // Config
  this.app = app;
  this.zoomLevel = app && app.zoomLevel ? app.zoomLevel : 1;
  this.textField = textField;
  this.fakeTextAreaId = config.fakeTextAreaId || 'fontCalcTextArea';
  this.root = config.root || document.body; // Element to append the fake textarea to
  this.maxFontSize = config.maxFontSize || TextUtils.DEFAULTS.maxFontSize; // Max font size allowed (in px)
  this.minFontSize = config.minFontSize || TextUtils.DEFAULTS.minFontSize; // Min font size allowed (in px)
  this.widthOffset = config.widthOffset || 0; // Offset to apply to the calculate text field width (in px)
  this.heightOffset = config.heightOffset || 0; // Offset to apply to the calculate text field height (in px)
  this.originalFontSize =
    config.originalFontSize || TextUtils.DEFAULTS.defaultFontSize;
  this.allowFontSizes = config.allowFontSizes || false;

  // Computed on the fly
  this.lineHeight = null;
  this.availableLineWidth = null;
  this.availableLineHeight = null;
};

TextUtils.DEFAULTS = {
  maxFontSize: 48,
  minFontSize: 7,
  defaultFontSize: 14,
};
TextUtils.CHAR_SIZE_MAP = {};
TextUtils.LETTER_SPACING_MAP = {};
TextUtils.NEW_LINE_CHAR_CODE = 10;
TextUtils.SPACE_CHAR_CODE = 32;

TextUtils.prototype.constructor = TextUtils;

//  ----  HELPERS  ----------------------------------------

TextUtils.prototype.createFakeTextArea = function () {
  const s = window.getComputedStyle(this.textField);
  const span = document.createElement('span');

  for (let i = 0; i < s.length; i++) {
    span.style[s[i]] = s[s[i]];
  }

  span.id = this.fakeTextAreaId;
  span.style.float = 'left';
  span.style.width = 'auto';
  span.style.visibility = 'hidden';
  span.style.padding = '0';
  span.style.border = '0 none';
  span.style.letterSpacing = '0';

  this.root.appendChild(span);

  return span;
};

TextUtils.prototype.calcCharSize = function (charCode, fontSize) {
  let fakeTextArea = document.getElementById(this.fakeTextAreaId);
  if (!fakeTextArea) {
    fakeTextArea = this.createFakeTextArea(this.textarea);
  }
  fakeTextArea.style['font-size'] = `${fontSize}px`;
  if (this.app && this.app.zoomLevel && this.app.zoomLevel !== this.zoomLevel) {
    this.zoomLevel = this.app.zoomLevel;
    fakeTextArea.style.zoom = `${100.0 * this.zoomLevel}%`;
  }
  fakeTextArea.innerHTML =
    charCode === 32 ? '&nbsp;' : String.fromCharCode(charCode);
  const rect = fakeTextArea.getBoundingClientRect();
  return rect.right - rect.left;
};

TextUtils.prototype.calcLetterSpacing = function (fontSize) {
  return 0.05 * fontSize;
};

TextUtils.prototype.queryCharSize = function (charCode, fontSize) {
  if (
    !TextUtils.CHAR_SIZE_MAP[charCode] ||
    !TextUtils.CHAR_SIZE_MAP[charCode][fontSize] ||
    !TextUtils.CHAR_SIZE_MAP[charCode][fontSize][this.zoomLevel]
  ) {
    // Calculate
    TextUtils.CHAR_SIZE_MAP[charCode] = TextUtils.CHAR_SIZE_MAP[charCode] || {};
    TextUtils.CHAR_SIZE_MAP[charCode][fontSize] =
      TextUtils.CHAR_SIZE_MAP[charCode][fontSize] || {};
    // Calling this method has a possible side effect of updating the zoom level, so we'll
    // calculate this prior to setting its value on CHAR_SIZE_MAP
    const charSize = this.calcCharSize(charCode, fontSize);
    TextUtils.CHAR_SIZE_MAP[charCode][fontSize][this.zoomLevel] = charSize;
  }
  return (
    TextUtils.CHAR_SIZE_MAP[charCode][fontSize][this.zoomLevel] +
    this.queryLetterSpacing(fontSize)
  );
};

TextUtils.prototype.queryLetterSpacing = function (fontSize) {
  if (!TextUtils.LETTER_SPACING_MAP[fontSize]) {
    TextUtils.LETTER_SPACING_MAP[fontSize] = this.calcLetterSpacing(fontSize);
  }
  return TextUtils.LETTER_SPACING_MAP[fontSize];
};

TextUtils.prototype.getAvailableWidth = function (styles) {
  if (this.availableLineWidth === null) {
    if (!styles) {
      styles = window.getComputedStyle(this.textField);
    }
    const originalWidth = parseFloat(styles.width, 10);
    const includePadding = styles['box-sizing'] != 'content-box';
    const paddingLeft =
      includePadding && styles['padding-left']
        ? parseFloat(styles['padding-left'], 10)
        : 0;
    const paddingRight =
      includePadding && styles['padding-right']
        ? parseFloat(styles['padding-right'], 10)
        : 0;
    this.availableLineWidth =
      originalWidth + this.widthOffset - paddingLeft - paddingRight;
  }
  return this.availableLineWidth;
};

TextUtils.prototype.getAvailableHeight = function () {
  if (this.availableLineHeight === null) {
    const styles = window.getComputedStyle(this.textField);
    const originalHeight = parseFloat(styles.height, 10);
    const includePadding = styles['box-sizing'] != 'content-box';
    const paddingTop =
      includePadding && styles['padding-top']
        ? parseFloat(styles['padding-top'], 10)
        : 0;
    const paddingBottom =
      includePadding && styles['padding-bottom']
        ? parseFloat(styles['padding-bottom'], 10)
        : 0;
    this.availableLineHeight =
      originalHeight - paddingTop - paddingBottom + this.heightOffset;
  }
  return this.availableLineHeight;
};

TextUtils.prototype.getLineHeight = function () {
  if (this.lineHeight === null) {
    const styles = window.getComputedStyle(this.textField);
    this.lineHeight = parseFloat(styles['line-height'], 10);
    if (isNaN(this.lineHeight)) {
      // Default to the font size then
      this.lineHeight = parseFloat(styles['font-size'], 10);
    }
  }
  return this.lineHeight;
};

TextUtils.prototype.isMultiLine = function () {
  // This is based on the original font size of the field
  const fontSize = (this.allowFontSizes ? this.originalFontSize : 16) * 1.5;
  return this.getAvailableHeight() > fontSize;
};

//  ----  PUBLIC API  -------------------------------------

TextUtils.prototype.getLines = function (forcedFontSize) {
  const text = this.textField.value;
  const styles = window.getComputedStyle(this.textField);
  const fontSize = forcedFontSize || parseFloat(styles['font-size'], 10);
  const availableLineWidth = this.getAvailableWidth(styles);
  let lineWidth = 0;
  const lines = [''];
  let lineNum = 0;
  let word = '';
  let wordWidth = 0;
  let charWidth = 0;

  for (let i = 0; i < text.length; i++) {
    const c = text.charCodeAt(i);

    if (c === TextUtils.NEW_LINE_CHAR_CODE) {
      // Add the current word
      if (wordWidth > 0) {
        lines[lineNum] += word;
        word = '';
        wordWidth = 0;
      }

      // Add the new line
      lineWidth = 0;
      lineNum++;
      lines.push('');
    } else if (c === TextUtils.SPACE_CHAR_CODE) {
      // Add the current word since we've reach its end
      if (lineWidth + wordWidth > availableLineWidth) {
        // Add the word to the next line since it can't fit on the current one
        lineWidth = wordWidth;
        lineNum++;
        lines.push(word);
      } else {
        // Add the word to the current line
        lineWidth += wordWidth;
        lines[lineNum] += word;
      }

      word = '';
      wordWidth = 0;

      // Add the space character
      charWidth = this.queryCharSize(c, fontSize);
      if (lineWidth + charWidth > availableLineWidth) {
        // NOTE: Only create a new line when it ends with a space.
        // Multiline text fields have this strange behavior where all spaces get squashed
        // until you type a non-space character.

        lineWidth = 0;
        lineNum++;
        lines.push('');

        // Skip over to the next non-space character
        for (let _i = i; _i < text.length; _i++) {
          if (text.charCodeAt(_i) !== TextUtils.SPACE_CHAR_CODE) {
            i = _i - 1;
            break;
          }
        }
      } else {
        // Add it to the current line
        lineWidth += charWidth;
        lines[lineNum] += text[i];
      }
    } else {
      charWidth = this.queryCharSize(c, fontSize);

      if (wordWidth + charWidth >= availableLineWidth) {
        // The current word is too long to fit on a single line so cut it
        lines[lineNum] += word;
        lines.push('');
        lineNum++;
        word = '';
        wordWidth = 0;
      }

      // Keep on building the current word
      word += text[i];
      wordWidth += charWidth;

      if (i === text.length - 1) {
        // Add the last word
        if (lineWidth + wordWidth > availableLineWidth) {
          // Add the last word to the next since it can't fit on the current one
          lines.push(word);
        } else {
          // Add the word to the current line
          lines[lineNum] += word;
        }
      }
    }
  }

  return lines;
};

TextUtils.prototype.getBestFitFontSize = function (isPrefilled) {
  if (!this.textField.value) {
    return this.originalFontSize;
  }

  let fontSize;
  // hack to make prefilled text (editable merge fields) smaller and fit in the textbox.
  // gives wiggle room since we can't accurately determine character count on backend to throw errors. -DW
  // TODO: resolve inconsistency of character estimation logic between frontend and backend (and remove this hack)
  // Also see: `signature-document/field/text-input` for a related hack
  const minSize = this.minFontSize - (isPrefilled ? 1 : 0); // 6 = smallest reasonably visible text

  let availableNumLines = Math.round(
    this.getAvailableHeight() / this.getLineHeight(),
  );
  if (this.isMultiLine()) {
    let numLines;
    for (fontSize = this.originalFontSize; fontSize >= minSize; fontSize--) {
      numLines = this.getLines(fontSize).length;
      // if font sizes are allowed, then check available num lines based on the font size
      if (this.allowFontSizes) {
        availableNumLines = Math.floor(this.getAvailableHeight() / fontSize);
      }

      if (numLines <= availableNumLines) {
        return fontSize;
      }
    }
  } else {
    const availableLineWidth = this.getAvailableWidth();
    // start from original font size & go down
    for (fontSize = this.originalFontSize; fontSize >= minSize; fontSize--) {
      const l = this.getTextLength(this.textField.value, fontSize);
      // changing this to be less than to fix some issues when using various font sizes
      if (l < availableLineWidth) {
        return fontSize;
      }
    }
  }
};

/**
 * Truncates the current text value at a given font size.
 *
 * TODO: This could use a refactor.  This function is pretty hard to follow and
 * likely not super performant.  There are a lot of edge cases to cover so I'm
 * leaving it for now.
 */
TextUtils.prototype.truncateTextValue = function (
  fontSize = TextUtils.DEFAULTS.minFontSize,
) {
  // Get total number of lines for the current text value at the forced font sized given
  const lines = this.getLines(fontSize);

  // if font sizes are allowed and it's multiline, use font size to check for the available lines
  const lineHeight =
    this.allowFontSizes && this.isMultiLine() ? fontSize : this.getLineHeight();
  // Compute the available number of lines
  const availableNumLines = Math.round(this.getAvailableHeight() / lineHeight);

  // Get the lines that fit within the available number of lines
  const fittingLines = lines.slice(0, availableNumLines);

  // The remaining lines do not fit
  const trailingLines = lines.slice(availableNumLines);
  const availableWidth = this.getAvailableWidth();

  // We need to try to merge in as many characters from the trailing line into
  // the last fitting line.  We start with the last fitting and line and
  // append each trailing character to the end of it and measure the width at
  // each iteration
  let lastFittingLine = fittingLines[fittingLines.length - 1];
  const trailingChars = trailingLines.join('');
  let i = 0;

  while (
    this.getTextLength(lastFittingLine + trailingChars[i], fontSize) <
      availableWidth &&
    i < trailingChars.length
  ) {
    lastFittingLine += trailingChars[i];
    i += 1;
  }

  // Take the untouched fitting lines and concat on the new last fitting line
  const newLines = fittingLines
    .slice(0, fittingLines.length - 1)
    .concat(lastFittingLine);

  return newLines.join('');
};

TextUtils.prototype.getTextLength = function (text, fontSize) {
  let l = 0;
  for (let i = 0; i < text.length; i++) {
    l += this.queryCharSize(text.charCodeAt(i), fontSize);
  }
  return l;
};

TextUtils.prototype.reset = function () {
  TextUtils.CHAR_SIZE_MAP = {};
  const el = document.getElementById(this.fakeTextAreaId);
  if (el) {
    document.body.removeChild(el);
  }
};

// A band aid for now while we transition away from these globals
window.TextUtils = TextUtils;

if (typeof module !== 'undefined') {
  module.exports = TextUtils;
}
