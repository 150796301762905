/* eslint-disable */
$(function() {
    var mouseInit = $.ui.mouse.prototype._mouseInit; 
    $.extend($.ui.mouse.prototype, {
        _mouseInit: function() {
            this.element.on("touchstart." + this.widgetName, $.proxy(this, "_touchStart"));

            mouseInit.apply(this, arguments);
        },
        _touchStart: function(event) {
            this.element
                .on("touchmove." + this.widgetName, $.proxy(this, "_touchMove"))
                .on("touchend." + this.widgetName, $.proxy(this, "_touchEnd"));

            event.which = 1;
            this._mouseStart(event);

            event.preventDefault();
        },

        _touchMove: function(event) {
            event.which = 1;
            this._mouseDrag(event);
        },

        _touchEnd: function(event) {
            this.element	
                .off("touchmove." + this.widgetName)
                .off("touchend." + this.widgetName);

            this._mouseStop(event);
        }
    });
});
