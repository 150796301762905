import React from 'react';

export function useTimeout() {
  // This will either be `number` or `NodeJS.Timeout`. When I started writing
  // this hook TS was fine with number, but then suddenly decided it's a
  // `NodeJS.Timeout` instead. I don't really care about the type, it just needs
  // to be the return value of setTimeout.
  type TimerReturn = ReturnType<typeof setTimeout>;
  const timers = React.useRef<TimerReturn[]>([]);
  React.useEffect(() => {
    return () =>
      timers.current.forEach((id) => {
        clearTimeout(id);
      });
  }, []);
  return React.useCallback(function setTimeoutWrapper<TArgs extends Array<any>>(
    handler: (...args: TArgs) => any,
    timeout: number,
    ...args: TArgs
  ) {
    const id = setTimeout(() => {
      timers.current = timers.current.filter((i) => i !== id);
      handler(...args);
    }, timeout);
    timers.current.push(id);
    return id;
  }, []);
}
