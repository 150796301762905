/* eslint-disable @typescript-eslint/no-unused-vars */

// https://developers.heap.io/reference#track
export type HeapProperties = Record<
  string | number,
  string | number | boolean | undefined
>;

declare global {
  interface Window {
    heap?: {
      track: (eventName: string, propertiesObj: HeapProperties) => void;
    };
  }
}

type Subscription = (eventName: string, propertiesObj: HeapProperties) => void;

export function useHeapSubscription(subscription: Subscription) {}

export function RelayHeapEvents() {
  useHeapSubscription((eventName, properties) => {});
  return null;
}

export function trackHeapCustomEvent(
  eventName: string,
  propertiesObj: HeapProperties = {},
) {}
