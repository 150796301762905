/* eslint-disable no-restricted-syntax */

import { intl } from 'hellospa/common/hs-intl-provider';
import { defineMessages } from 'react-intl';

window.hsIntl = intl;

window.hsMessages = defineMessages({
  hasNotEditedDoc: {
    id: '',
    description: 'document uploading, Prepare document for signing button text',
    defaultMessage: 'Prepare doc for signing',
  },
  hasNotEditedDocs: {
    id: '',
    description:
      'document uploading, Prepare documents for signing button text',
    defaultMessage: 'Prepare docs for signing',
  },
  hasEditedDoc: {
    id: '',
    description: 'Prepare doc for signing button text',
    defaultMessage: 'Re-edit doc for signing',
  },
  hasEditedDocs: {
    id: '',
    description: 'Prepare docs for signing button text',
    defaultMessage: 'Re-edit docs for signing',
  },
  attachmentError: {
    id: '',
    description: "Don't forget to attach a file error",
    defaultMessage: "Don't forget to attach a file",
  },
  waitUploadAndConvertError: {
    id: '',
    description:
      'Please wait until your files finish uploading and converting error message',
    defaultMessage:
      'Please wait until your files finish uploading and converting.',
  },
  externalServiceGoogleDriveContentModal: {
    id: '',
    description:
      'Body message on Google Drive pop up modal for external service integration',
    defaultMessage:
      '<p class="auth_text">To use your Google Drive files, you must install and launch the {brandName} application. Click Continue to install the application, once there <span style="font-weight: var(--type__body__standard--fontweight_strong);">Install</span> then <span style="font-weight: var(--type__body__standard--fontweight_strong);">Launch</span> the application.</p><p class="button_container"><a id="auth_link" target="_blank" rel="noopener noreferrer" class="btn-replacement" id="" href="{authUrl}">Continue</a></p>',
  },
  externalServiceEvernoteContentModal: {
    id: '',
    description:
      'Body message on Evernote pop up modal, external service integration',
    defaultMessage:
      '<p class="auth_text">To use your Evernote files, you must first link your {brandName} account. We will also create a {brandName} notebook. Anytime you send or receive a document, it will be backed up as a note.</p><p class="button_container"><a id="auth_link" target="_blank" rel="noopener noreferrer" class="btn-replacement" id="" href="{authUrl}">Continue</a></p>',
  },
  externalServiceDropboxContentModal: {
    id: '',
    description:
      'Body message on Dropbox pop up modal, external service integration',
    defaultMessage:
      '<p class="auth_text">To use your Dropbox files, you must first link your {brandName} account. We will also create a {brandName} folder in your Dropbox. Anytime you send or receive a document, it will be backed up in your Dropbox account.</p><p class="button_container"><a id="auth_link" target="_blank" rel="noopener noreferrer" class="btn-replacement" id="" href=" {authUrl} escape(\'?dropbox_set_sync=1\')">Continue</a></p>',
  },
  externalServiceBoxContentModal: {
    id: '',
    description:
      'Body message on Dropbox pop up modal, external service integration',
    defaultMessage:
      '<p class="auth_text">To use your Box files, you must first authorize {brandName} to access files in your Box account.</p><p class="button_container"><a id="auth_link" target="_blank" rel="noopener noreferrer" class="btn-replacement" id="" href="{authUrl}">Continue</a></p>',
  },
  externalServiceOneDriveContentModal: {
    id: '',
    description:
      'Body message on Dropbox pop up modal, external service integration',
    defaultMessage:
      '<p class="auth_text">To use your OneDrive files, you must first link your {brandName} account. We will also create a {brandName} folder in your OneDrive. Anytime you send or receive a document, it will be backed up in your OneDrive account.</p><p class="button_container"><a id="auth_link" target="_blank" rel="noopener noreferrer" class="btn-replacement" id="" href="{authUrl}">Continue</a></p>',
  },
  externalServiceGoogleDriveReauthorize: {
    id: '',
    description:
      'Body message on Google Drive pop up modal for Re-authorization external service integration',
    defaultMessage:
      '<p class="auth_text"><u>Google Drive has upgraded the way they handle authorizations</u>. As a result you must reauthorize {brandName} to access files in your Drive account.</p><p class="button_container"><a id="auth_link" target="_blank" rel="noopener noreferrer" class="btn-replacement" id="" href="{authUrl}">Re-authorize</a></p>',
  },
  externalServiceBoxReauthorize: {
    id: '',
    description:
      'Body message on Box pop up modal for Re-authorization external service integration',
    defaultMessage:
      '<p class="auth_text"><u>Box.net has upgraded the way they handle authorizations</u>. As a result you must reauthorize {brandName} to access files in your Box account.</p><p class="button_container"><a id="auth_link" target="_blank" rel="noopener noreferrer" class="btn-replacement" id="" href="{authUrl}">Re-authorize</a></p>',
  },
  externalServiceAuthError: {
    id: '',
    description:
      'Authorization error on external services integration. not access denied',
    defaultMessage:
      'Sorry something went wrong, please try again later. Error: { authResultError}',
  },
  attachmentRetrieving: {
    id: '',
    description: 'Status text while retrieving',
    defaultMessage: 'RETRIEVING',
  },
  attachmentConverting: {
    id: '',
    description: 'Status text while uploading',
    defaultMessage: 'CONVERTING',
  },
  attachmentUploadingAndName: {
    id: '',
    description: "'Uploading + filename text' while uploding the filename",
    defaultMessage: 'UPLOADING: { filename }',
  },
  addCC: {
    id: '',
    description: 'Add CC button text',
    defaultMessage: 'Add CC',
  },
  addTemplatePopUpModal: {
    id: '',
    description: 'Text body on the modal pop for add Template button',
    defaultMessage:
      '<div id="external_auth" class="dropbox"><p class="logo_text">Templates* </p><p class="auth_text">Asking people to sign the same document frequently?  Create a template!  It\'s great for NDAs, employee contracts and other standard documents.</p><p class="button_container"><a id="auth_link" target="_blank" rel="noopener noreferrer" class="btn-replacement" href="/home/createReusableDocs">Create a Template</a></p></div>',
  },
  forgetPrepareAndAssign: {
    id: '',
    description:
      'Error message when user forgets to prepare the document and/or assign fields to signers',
    defaultMessage:
      "Don't forget to prepare the documents and assign fields to all signers.",
  },
  forgetEditDocument: {
    id: '',
    description: 'Error message when user forgets to edit the document',
    defaultMessage: "Don't forget to edit the documents.",
  },
  noFieldAssigned: {
    id: '',
    description:
      'Error message when no field is assigned to signers, placeholders: errorMessage - detail message thrown at some point in execution of a process.',
    defaultMessage: 'No fields are currently assigned to {signer_name}.',
  },
  noFieldAssignedToTwo: {
    id: '',
    description:
      'Error message when no field is assigned to signers, placeholders: errorMessage - detail message thrown at some point in execution of a process.',
    defaultMessage:
      'No fields are currently assigned to {first_signer_name} and {second_singer_name}.',
  },
  noFieldAssignedToMoreThenTwo: {
    id: '',
    description:
      'Error message when no field is assigned to signers, placeholders: errorMessage - detail message thrown at some point in execution of a process.',
    defaultMessage:
      'No fields are currently assigned to {first_signer_name}, {second_singer_name} and more.',
  },
  missingRecipients: {
    id: '',
    description:
      'Error message when there are missing recipients in the template',
    defaultMessage:
      'At least one role is required. Please enter name and email address.',
  },
  maxAllowedPageError: {
    id: '',
    description: 'Error message to display how many pages are allowed',
    defaultMessage: 'The maximum total number of pages allowed is {maxPages}.',
  },
  fileWithTooManyPages: {
    id: '',
    description: 'Error message showing that a file has too many pages',
    defaultMessage: 'That file has too many pages.',
  },
  documentConvertError: {
    id: '',
    description: 'Error Message when there is document conversion error',
    defaultMessage:
      'We could not convert your file. Try saving it in another format or email us at {supportEmail}',
  },
  passworDocumentError: {
    id: '',
    description:
      'Error Message when the uploaded file/ file to convert is password protected',
    defaultMessage:
      'This file requires a password. Please remove the password and try again.',
  },
  importFileTooLarge: {
    id: '',
    description: 'Error Message when file to be imported are too large',
    defaultMessage: 'This file is too large to be imported ({fileSize}MB max)',
  },
  fileRetrievalError: {
    id: '',
    description:
      'Error Message when there is an error retrieving a file that needs to be converted',
    defaultMessage:
      'There was an error retrieving the file. Please try again later.',
  },
  missingDocumentTitle: {
    id: '',
    description:
      'Error message when document title is missing while requesting signature',
    defaultMessage: 'Please provide a document title.',
  },
  badAuthSync: {
    id: '',
    description:
      'Error message when the user is signed out with the external services, or the synchronization is not working',
    defaultMessage:
      'We could not retrieve this file, please re-establish sync for this service',
  },
  badRequesthSync: {
    id: '',
    description:
      'Error message when the user is unable to retrieve file associated to an email from external services',
    defaultMessage:
      'We could not retrieve this file, please re-establish sync for this service',
  },
  createTemplate: {
    id: '',
    description:
      'loading modal message in home create template page stating that template is being created',
    defaultMessage: "We're creating your template",
  },
  createTemplateLink: {
    id: '',
    description:
      'loading modal message in home create template link page stating that template link is being created',
    defaultMessage: "We're creating your link",
  },
  addSigningRole: {
    id: '',
    description:
      'error message in home create template page, asking user to input signing role',
    defaultMessage: 'Please add a Signing role.',
  },
  fieldRequired: {
    id: '',
    description:
      'error message for input field that is set to "required" when not filled',
    defaultMessage: 'This field is required.',
  },
  fieldFix: {
    id: '',
    description: 'error message in input field that needs to be fixed',
    defaultMessage: 'Please fix this field.',
  },
  emailRequired: {
    id: '',
    description:
      'error message in email input field that requires valid email address',
    defaultMessage: 'Please enter a valid email address.',
  },
  validUrl: {
    id: '',
    description: 'error message in input field that requires valid url',
    defaultMessage: 'Please enter a valid URL.',
  },
  validDate: {
    id: '',
    description: 'error message in input field that requires valid date',
    defaultMessage: 'Please enter a valid date.',
  },
  validDateISO: {
    id: '',
    description: 'error message in input field that requires valid dateISO',
    defaultMessage: 'Please enter a valid date (ISO).',
  },
  validNumber: {
    id: '',
    description: 'error message in input field that requires valid number',
    defaultMessage: 'Please enter a valid number.',
  },
  validDigits: {
    id: '',
    description: 'error message in input field that requires valid digits',
    defaultMessage: 'Please enter only digits.',
  },
  validCreditCard: {
    id: '',
    description: 'error message in input field that requires valid credit card',
    defaultMessage: 'Please enter a valid credit card number.',
  },
  passwordMatch: {
    id: '',
    description:
      "error message appears when both entered password doesn't match",
    defaultMessage: "Your passwords don't match.",
  },
  validExtension: {
    id: '',
    description: 'error message in input field that requires valid extension',
    defaultMessage: 'Please enter a value with a valid extension.',
  },
  noMoreThanCharacters: {
    id: '',
    description:
      'error message in input field that requires user to input certain number of character',
    defaultMessage: 'Please enter no more than {num_char} characters.',
  },
  atLeastNumCharacter: {
    id: '',
    description:
      'error message in input field that requires user to input certain number of character',
    defaultMessage: 'Please enter at least {num_char} characters.',
  },
  valueBetweenLongCharacter: {
    id: '',
    description:
      'error message in input field that requires user to input certain number of character',
    defaultMessage:
      'Please enter a value between {first_num_char} and {second_num_char} characters long.',
  },
  valueBetweenCharacter: {
    id: '',
    description:
      'error message in input field that requires user to input certain number of character',
    defaultMessage:
      'Please enter a value between {first_num_char} and {second_num_char}.',
  },
  lessThanOrEqualCharacter: {
    id: '',
    description:
      'error message in input field that requires user to input certain number of character',
    defaultMessage: 'Please enter a value less than or equal to {num_char}.',
  },
  greaterThenOrEqualCharacter: {
    id: '',
    description:
      'error message in input field that requires user to input certain number of character',
    defaultMessage: 'Please enter a value greater than or equal to {num_char}.',
  },
  inactiveLogoutOKtoLgoinWarning: {
    id: '',
    description:
      'warning message in window prompt, user got logout due to inactivity',
    defaultMessage:
      "You're about to be logged out due to inactivity. Click on 'OK' within the next minute to stay logged in.",
  },
  inactiveLogoutWarning: {
    id: '',
    description:
      'warning message in window prompt, user got logout due to inactivity',
    defaultMessage: 'You have been logged out due to inactivity.',
  },
  twoCCsIdenticalWarning: {
    id: '',
    description:
      'warning message in prep and send page, indicating two cc user inputted are same',
    defaultMessage: 'At least two CCs are identical',
  },
  twoSignersIdenticalWarning: {
    id: '',
    description:
      'warning message in prep and send page, indicating two signer user inputted are same',
    defaultMessage: 'At least two signers are identical',
  },
  twoRecipientIdenticalWarning: {
    id: '',
    description:
      'warning message in prep and send page, indicating two recipient user inputted are same',
    defaultMessage: 'At least two recipients are identical',
  },
  twoRolesIdenticalWarning: {
    id: '',
    description:
      'warning message in prep and send page, indicating two recipient user inputted are same',
    defaultMessage: 'At least two roles are identical',
  },
  ccPlaceholder: {
    id: '',
    description:
      'placeholder text in home page where sender puts the role type of the signature request',
    defaultMessage: 'CC Role (Manager, Client, etc.)',
  },
  signerPlaceholder: {
    id: '',
    description:
      'placeholder text in home page where sender puts the role type of the signature request',
    defaultMessage: 'Signing Role (Manager, Client, etc.)',
  },
  signerInputFieldTitle: {
    id: '',
    description:
      "title text for input stating signer's role type is required to proceed",
    defaultMessage: 'Please add a Signing role.',
  },
  ccInputFieldTitle: {
    id: '',
    description:
      "title text for input stating signer's role type is required to proceed",
    defaultMessage: 'Please add a CC role.',
  },
  coverPageHelloFaxFree: {
    id: '',
    description:
      'HelloFax, cover page, modal, informs user that HelloFax branding will be included for free users and',
    defaultMessage:
      'For free users, the cover page will include "Sent via HelloFax". <upgrade_link>Upgrade now</upgrade_link>.',
  },
  coverPageAddLink: {
    id: '',
    description:
      'HelloFax, cover page, actionable link, activates modal for adding cover page',
    defaultMessage: '+ Cover page',
  },
  coverPageEditLink: {
    id: '',
    description:
      'HelloFax, cover page, actionable link, activates modal for editing cover page',
    defaultMessage: '- Edit cover page',
  },
  coverPageAddHeader: {
    id: '',
    description:
      'HelloFax, cover page, modal, header, proposes to user to add cover page to their fax',
    defaultMessage: 'Add a cover page',
  },
  coverPageAddSave: {
    id: '',
    description:
      'HelloFax, cover page, modal, button, saves entered cover page',
    defaultMessage: 'Save',
  },
  customMessageAddLink: {
    id: '',
    description:
      'HelloFax, custom message page, actionable link, activates modal for adding custom message',
    defaultMessage: '+ Custom message',
  },
  customMessageEditLink: {
    id: '',
    description:
      'HelloFax, custom message page, actionable link, activates modal for editing custom message',
    defaultMessage: '- Edit custom message',
  },
  customMessageInfo: {
    id: '',
    description:
      'HelloFax, custom message page, information, informs user that entered custom message will appear in the email',
    defaultMessage:
      '<i>This message will appear in the email that the recipient receives.</i>',
  },
  customMessageText: {
    id: '',
    description:
      'HelloFax, custom message page, placeholder, indicates field for inputting message text',
    defaultMessage: 'Add a custom message',
  },
  customMessageSave: {
    id: '',
    description:
      'HelloFax, cover page, modal, button, saves entered custom message',
    defaultMessage: 'Save Message',
  },
  sendButton: {
    id: '',
    description:
      'HelloFax, sending document, button, activates sending of the document to fax or email',
    defaultMessage: 'Send',
  },
  sendButtonHS: {
    id: '',
    description:
      'HelloFax, sending document, button, activates sending of the document through HelloSign',
    defaultMessage: 'Send via HelloSign',
  },
  countriesSupported: {
    id: '',
    description: 'Link to our internaional fax pricing page',
    defaultMessage: 'Click to see all countries we support',
  },
  secondOrSeconds: {
    id: '',
    description:
      'when there is a countdown javascript takes responsibility to actual display it dynamically to user, with time at some point seconds and second have to interchange, this translation will be used in such cases',
    defaultMessage: '{seconds, plural, one {second} other {seconds}}',
  },
  importContacts: {
    id: '',
    description: 'Menu item to open an Impor Contacts modal',
    defaultMessage: 'Import Contacts',
  },
  noName: {
    id: '',
    description:
      'tooltip text, indicating no name is associated with the email input field',
    defaultMessage: 'No Name',
  },
  accessCodeTitle: {
    id: '',
    description: 'Access Code modal title',
    defaultMessage: 'Create an access code',
  },
  fourAlphaNumericAccessErrMsg: {
    id: '',
    description:
      'error message, appears when user tries to set wrong access code',
    defaultMessage: 'Please enter at least 4 alphanumeric characters.',
  },
  accessCodeBody: {
    id: '',
    description:
      'text in access code modal, asking user to set the access code for receipient',
    defaultMessage:
      'The recipient will be required to enter the access code you specify below to view and sign the document.',
  },
  accessCodeNotice: {
    id: '',
    description:
      'text in singer flow, notifying signer access code field is case sensitive and to put exactly as they received',
    defaultMessage: 'Note: the access code is case sensitive.',
  },
  accessCodeSave: {
    id: '',
    description: 'Access Code save button',
    defaultMessage: 'Save and close',
  },
  smsAuthHeader: {
    id: '',
    description:
      'SMS authentication header requiring sender to enter a phone number for the sender',
    defaultMessage: 'Add a phone number',
  },
  smsAuthBody: {
    id: '',
    description:
      'SMS authentication body letting sender know a code will be sent via SMS to signer',
    defaultMessage:
      'The recipient will need to enter the code received via SMS at the country code and phone number you provide below in order to view and sign the document.',
  },
  smsAuthSave: {
    id: '',
    description: 'SMS auth save button',
    defaultMessage: 'Save and close',
  },
  smsAuthCancel: {
    id: '',
    description: 'SMS auth cancel button',
    defaultMessage: 'Cancel',
  },
  smsAuthZeroLeft: {
    id: '',
    description:
      'SMS authentication error message letting the sender know that there are not enough SMS verifications left',
    defaultMessage: 'Not enough SMS verifications left',
  },
  smsValidCountryCodeErrMsg: {
    id: '',
    description: 'error message, appears when user enters invalid country code',
    defaultMessage: 'Please enter a valid country code',
  },
  smsInputDigitsErrMsg: {
    id: '',
    description:
      'error message, appears when user does not enter between 1 and 15 digits',
    defaultMessage: 'Please enter between 1-15 digits',
  },
  smsInputNumRequiredErrMsg: {
    id: '',
    description:
      'error message, appears when user does not enter a valid phone number',
    defaultMessage: 'Please enter a valid phone number',
  },
  checkAll: {
    id: '',
    description: 'link text, when clicked checks all the fields',
    defaultMessage: 'Check all',
  },
  UnCheckAll: {
    id: '',
    description: 'link text, when clicked checks all the fields',
    defaultMessage: 'Uncheck all',
  },
  templates: {
    id: '',
    description:
      'A table column heading for the number of templates in each teams.',
    defaultMessage: 'Templates',
  },
});
