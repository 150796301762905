import React from 'react';
import { IntlFormatters, useIntl } from 'react-intl';
import ReactDOM from 'react-dom';
import * as uuid from 'uuid';
import styles from 'signer-app/utils/use-aria-live.module.css';
import useDiv from 'signer-app/utils/use-div';
import { useTimeout } from 'signer-app/utils/use-timeout';

type FormatMessageParameters = Parameters<IntlFormatters['formatMessage']>;

export type MessageValues = FormatMessageParameters[1];

type AnnounceFn = (
  message: FormatMessageParameters[0],
  values?: MessageValues,
) => void;

const ariaContext = React.createContext<AnnounceFn | undefined>(undefined);

export function useAriaLive(): AnnounceFn {
  const announce = React.useContext(ariaContext);
  if (!announce) {
    throw new Error('Missing AriaLiveContextProvider');
  }

  return announce;
}

export interface AriaLiveProp {
  announce: AnnounceFn;
}

export function withAriaLiveContext<P>(
  Component: React.ComponentType<
    P & AriaLiveProp & React.RefAttributes<object>
  >,
) {
  // eslint-disable-next-line react/display-name
  return React.forwardRef((props: P, ref: React.Ref<any>) => (
    <ariaContext.Consumer>
      {(announce) => {
        if (!announce) {
          throw new Error('Missing AriaLiveContextProvider');
        }
        return <Component {...props} announce={announce} ref={ref} />;
      }}
    </ariaContext.Consumer>
  ));
}

// This component is rendered as part of `<HSIntlProvider`, so we shouldn't need
// it anywhere else in the app.
export function AriaLiveContextProvider({
  children,
}: React.PropsWithChildren<object>) {
  const intl = useIntl();
  const portalDiv = useDiv();
  const setTimeout = useTimeout();

  type Announcement = {
    id: string;
    text: string | React.ReactNode;
  };
  const [announcements, setAnnouncements] = React.useState<Announcement[]>([]);

  const remove = React.useCallback((announcement: Announcement) => {
    setAnnouncements((all) => {
      return all.filter((item) => item.id !== announcement.id);
    });
  }, []);

  const announce: AnnounceFn = React.useCallback(
    (message, values) => {
      const id = uuid.v4();
      const text = intl.formatMessage(message, values);
      const announcement = {
        id,
        text,
      };
      setTimeout(remove, 10000, announcement);

      setAnnouncements((all) => {
        return [...all, announcement];
      });
    },
    [intl, remove, setTimeout],
  );

  const liveRegion = ReactDOM.createPortal(
    <div
      className={styles.ariaLive}
      data-qa-ref="aria-live"
      aria-live="assertive"
      aria-relevant="additions"
    >
      {announcements.map((a) => (
        <div
          data-testid="aria-announcement"
          data-qa-ref="aria-announcement"
          key={a.id}
        >
          {a.text}
        </div>
      ))}
    </div>,
    portalDiv,
  );

  return (
    <ariaContext.Provider value={announce}>
      {children}
      {liveRegion}
    </ariaContext.Provider>
  );
}
