import React from 'react';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { AriaLiveContextProvider } from 'signer-app/utils/use-aria-live';

export const currency = 'USD';
export const symbol = '$';

declare global {
  interface Window {
    localeMessages?: Record<string, string>;
    experimentStrings?: Record<string, string>;
    locale?: string;
  }
}

// To activate this in development run this in the console and refresh the page:
// localStorage.intlMessages = {}
const storageKey = 'intlMessages';

/**
 * This function reads `window.locale` and `window.localeMessages` if they exist.
 * The backend selects the locale by including an optional `<script` pointing to one of our
 * bundle files generated by `generateLocales()` in `webpack.config.js`
 *
 * @AppExplorer https://miro.com/app/board/uXjVPXskloA=/?moveToWidget=3458764535232104522&cot=14
 */
export const { messages, locale } = (function ConvertMessages() {
  const { locale = 'en-US' } = window;
  const messages = {} as Record<string, string>;

  function collectSmartlingMessages(mess?: {
    [key: string]: { translation: string };
  }) {
    const messagesLocal = {} as Record<string, string>;
    if (mess) {
      const keys = Object.keys(mess);
      for (let i = 0; i < keys.length; i++) {
        const id = keys[i];
        messagesLocal[id] = mess[id].translation;
      }
    }
    return messagesLocal;
  }

  if (
    // QA uses a development build
    NODE_ENV !== 'production' &&
    window.location.host.includes('app.dev-')
  ) {
    // localStorage my throw if it's being blocked
    try {
      if (localStorage[storageKey] != null) {
        // eslint-disable-next-line global-require
        const debugMessages = JSON.parse(localStorage[storageKey]);
        Object.assign(
          messages,
          collectSmartlingMessages(debugMessages.messages),
        );
      }
    } catch (e) {
      // do nothing
    }
  }
  Object.assign(messages, window.localeMessages, window.experimentStrings);
  // 29c and 30a are the same message. 30a is coming back with the wrong
  // encoding, so I changed the encoding and message and then 29c also came back
  // with the wrong encoding. I'm not waiting anymore, this upgrade is blocking
  // a series of upgrades so it's time to just hack in whatever will work
  // today.
  if (
    !messages[
      '29c0c24424fb173085ac7a54ac1f21243b2baa9a2ed5aee79265922c40a488ab'
    ]
  ) {
    messages[
      '29c0c24424fb173085ac7a54ac1f21243b2baa9a2ed5aee79265922c40a488ab'
    ] =
      messages[
        '30a24648691feccb12c00ec7b18db9725b1929d9d920c60a99a9d9f5c8b8118f'
      ];
  }

  if (
    messages['29c0c24424fb173085ac7a54ac1f21243b2baa9a2ed5aee79265922c40a488ab']
  ) {
    messages[
      '29c0c24424fb173085ac7a54ac1f21243b2baa9a2ed5aee79265922c40a488ab'
    ] = messages[
      '29c0c24424fb173085ac7a54ac1f21243b2baa9a2ed5aee79265922c40a488ab'
    ].replaceAll('&quot;', '"');
  }

  return { messages, locale };
})();

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

const [language] = locale.split('-');
/* eslint-disable global-require, comma-dangle, import/no-dynamic-require */
// SyntaxError: Trailing comma is disallowed inside import(...) arguments
if (!('PluralRules' in Intl)) {
  require('@formatjs/intl-pluralrules/polyfill');
  try {
    require(`@formatjs/intl-pluralrules/dist/locale-data/${language}`);
  } catch (e) {
    require('@formatjs/intl-pluralrules/dist/locale-data/en');
  }
}

if (!('RelativeTimeFormat' in Intl)) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  try {
    require(`@formatjs/intl-relativetimeformat/dist/locale-data/${language}`);
  } catch (e) {
    require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
  }
}

if (!('ListFormat' in Intl)) {
  require('@formatjs/intl-listformat/polyfill');
  try {
    require(`@formatjs/intl-listformat/dist/locale-data/${language}`);
  } catch (e) {
    require('@formatjs/intl-listformat/dist/locale-data/en');
  }
}
/* eslint-enable global-require, comma-dangle, import/no-dynamic-require */

const errors: Record<string, boolean> = {};

// Please try to avoid having to import this singleton directly. Doing so makes
// this a compile-time dependency instead of a runtime dependency. That will
// cause problems if we want to do things like load our messages in chunks.
// `intl` can't be modified, it just has to get replaced, and we can't do that
// when things import this directly.
export const intl = createIntl(
  {
    locale,
    messages: {
      ...messages,
    },
    onError: (error) => {
      if (NODE_ENV !== 'production') {
        // Suppress errors about missing messages. We never supply messages for
        // English, so they're always "missing"
        if (!window.localeMessages && error.code === 'MISSING_TRANSLATION') {
          return;
        }

        // Only show the error once. The default implementation spams the console
        // on every render.
        if (!errors[error.message]) {
          errors[error.message] = true;
          // eslint-disable-next-line no-console
          console.error('react-intl error', error);
        }
      }
    },
  },
  cache,
);

export default function HSIntlProvider({
  children,
  overrideIntl = intl,
}: React.PropsWithChildren<{
  overrideIntl?: typeof intl;
}>) {
  return (
    <RawIntlProvider value={overrideIntl}>
      <AriaLiveContextProvider>{children}</AriaLiveContextProvider>
    </RawIntlProvider>
  );
}

export const code = (chunks: any[]) => <code>{chunks}</code>;
export const strong = (chunks: any[]) => <strong>{chunks}</strong>;
