/* eslint-disable */
(function() {

    var lastAjaxCallTime = Math.round(new Date().getTime() / 1000);
    var originalMethod = jQuery.ajax;
    var sameDomainTopWindow = hellofaxJS.topWindowInDomain();

    // Automatically execute any analytics_js property returned in JSON response
    jQuery.ajax = function() {
        var inIntraDomainIFrame = window.location != sameDomainTopWindow.location;
        var timestamp = Math.round(new Date().getTime() / 1000);
        hellofaxJS.setLastAjaxTimestamp(timestamp);
        if (inIntraDomainIFrame && sameDomainTopWindow.hellofaxJS !== undefined) {
            sameDomainTopWindow.hellofaxJS.setLastAjaxTimestamp(Math.round(new Date().getTime() / 1000) - 10);
        }

        if (arguments.length > 1 && typeof arguments[0] !== "object") {
            // we have to do this because in the case where Raven (sentry) is calling this method
            // it uses the optional first 'url' argument of .ajax which we don't typically expect
            arguments[0] = arguments[1];
        }

        if (arguments.length > 0 && arguments[0] !== null) {

            // Get session info from query string and adds it to the request url if necessary
            arguments[0].url = hellofaxJS.attachSessionInfoToUrl(arguments[0].url);

            var originalOnSuccess = arguments[0].success !== undefined ? arguments[0].success : null;

            //force all ajax requests to include a cache buster
            var has_cache_buster = false;

            if (arguments[0].data && typeof arguments[0].data === 'string') { //string
                has_cache_buster = /[?&]?c=[\d.]+/.test(arguments[0].data);
            }
            else if (arguments[0].data && typeof arguments[0].data === 'object') { //object
                has_cache_buster = arguments[0].data.c && /^[\d.]+$/.test(arguments[0].data.c);
            }


            if (!has_cache_buster) {

                //check url
                has_cache_buster = /[?&]?c=[\d.]+/.test(arguments[0].url);

                //if still no cache buster, add one
                arguments[0].url += (arguments[0].url.indexOf('?') >= 0 ? '&' : '?') + 'c=' + Math.random();
            }


            arguments[0].success = function() {
                if (arguments.length > 0 && arguments[0] !== null && arguments[0] !== undefined) {
                    if (arguments[0].analytics_js !== undefined && arguments[0].analytics_js.length > 0) {
                        $(arguments[0].analytics_js).appendTo('body');
                    }

                    if (arguments[0].redirect_url !== undefined && arguments[0].redirect_url.length > 0) {
                        if (inIntraDomainIFrame) {
                            // in an iframe
                            sameDomainTopWindow.$('body').data('is_redirecting', true);
                            $(window).unbind('beforeunload');
                            sameDomainTopWindow.$.fancybox.close();
                            sameDomainTopWindow.location = arguments[0].redirect_url;
                        }
                        else {
                            $('body').data('is_redirecting', true);
                            window.location = arguments[0].redirect_url;
                        }

                        return; // short-circuit and do not ever call originalOnSuccess
                    }
                }

                if (originalOnSuccess) {
                    originalOnSuccess.apply(this, arguments);
                }
            };
        }

        return originalMethod.apply(this, arguments);
    };
})();
