/* eslint-disable */
(function($) {

    $.widget("the.canvas", $.ui.mouse, {
    
        options: $.extend( {}, $.ui.mouse.options, {
            appendTo: "body",
            distance: 0,
            callback: null
        }),
    
        _init: function() {
            this._mouseInit();
            this.canvas = document.getElementById(this.element[0].id);
            this.isPainting = true;
            this.isOffCanvas = false;
            this.dragOffset = null;
            this.points = [];
            this.pointsSinceLineWeightCheck = 0;
            this.defaultLineWidth = 3;
            this.ease = 0.7;
            var canvasHelper = this;
            
            window.scrolled = false;
            
            $(window).on('scroll resize', function() {
                window.scrolled = true;
            });
        },

        destroy: function() {
            this._mouseDestroy();
        },
        
        _mouseStart: function(event) {
            event.preventDefault();

            this.context = this.canvas.getContext('2d');
            if(this.context) {
                if (this.dragOffset === null) {
                    this.dragOffset = $(event.target).offset();
                }
                this.context.fillStyle = 'rgba(200, 200, 200, 0.3)';
                this.context.lineCap = 'round';
                this.context.lineWidth = this.defaultLineWidth;
                this.isPainting = true;
                this.isOffCanvas = false;
                //silly version of Date.now() for IE8
                this.duration = +new Date;

                var pointX = event.pageX - this.dragOffset.left;
                var pointY = event.pageY - this.dragOffset.top;

                if (event.originalEvent && event.originalEvent.touches) {
                    pointX = event.originalEvent.touches[0].pageX - this.dragOffset.left;
                    pointY = event.originalEvent.touches[0].pageY - this.dragOffset.top;
                }

                var point = { 'x' : pointX, 'y' : pointY };
                this.points.push(point);

                if (this.options.callback) {
                    this.options.callback('start', point);
                }
            }
        },
    
        _mouseDrag: function(event) {
            this.isDragging = true;
            if (this.context && this.isPainting && !this.isOffCanvas) {
                if (this.dragOffset === null || window.scrolled) {
                    if (window.scrolled) {
                        this.lastDragOffset = this.dragOffset;
                    }
                
                    this.dragOffset = $(event.target).offset();
                    window.scrolled = false;
                }
                
                var pointX = event.pageX - this.dragOffset.left;
                var pointY = event.pageY - this.dragOffset.top;

                if (event.originalEvent && event.originalEvent.touches) {
                    pointX = event.originalEvent.touches[0].pageX - this.dragOffset.left;
                    pointY = event.originalEvent.touches[0].pageY - this.dragOffset.top;
                }

                var point = { 'x': pointX, 'y': pointY };
                this.points.push(point);
                this.pointsSinceLineWeightCheck++;
                this.drawCurvyLine();

                if (this.options.callback) {
                    this.options.callback('drag', point);
                }
            }
        },
    
        _mouseStop: function(event) {
            //silly version of Date.now() for IE8
            this.duration = (+new Date) - this.duration;

            if (this.isDragging) {
                this.drawLine();
            } 
            else {
                this.drawPoint();
            }
            this.pointsSinceLineWeightCheck = 0;
            this.context.lineWidth = this.defaultLineWidth;
            this.isPainting = false;        
            this.isDragging = false;
            this.isOffCanvas = false;
            this.canvas.blur();
            this.context.save();

            if (this.options.callback) {
                this.options.callback('stop');
            }
        },

        lineWeightCheck: function () {
            var points = this.points,
                i = points.length - 1,
                d = 0,
                lineWidthShouldBe = 0;
            if (i > 0) {
                // find out how fast the mouse is moving, and fast the change is changing
                // and adjust stroke width perhaps
                d = Math.sqrt(Math.pow(points[i].x - points[i-1].x, 2) + Math.pow(points[i-1].y - points[i-1].y, 2));
                // correct line length
                if (d < 1) {
                    lineWidthShouldBe = 5;
                }
                else if (d < 3) {
                    lineWidthShouldBe = 4;
                }
                else if (d < 15) {
                    lineWidthShouldBe = 3;
                }
                else if (d < 20) {
                    lineWidthShouldBe = 2;
                }
                else {
                    lineWidthShouldBe = 1;
                }
                
                if (d < 10 && this.pointsSinceLineWeightCheck > 10 ||
                    d > 10 && this.pointsSinceLineWeightCheck > 5) {
                    if (lineWidthShouldBe > this.context.lineWidth) {this.context.lineWidth++;}
                    else if (lineWidthShouldBe < this.context.lineWidth) {this.context.lineWidth--;}
                    this.pointsSinceLineWeightCheck = 0;
                }
            }
        },

        drawPoint: function() {
            var points = this.points,
                ctx = this.context,
                d = 1;
            if (points.length == 1) {
                if (this.duration > 1000) {
                    d = 6;
                }
                else if (this.duration > 600) {
                    d = 5;
                }
                else if (this.duration > 320) {
                    d = 4;
                }
                else if (this.duration > 220) {
                    d = 3;
                }
                else if (this.duration > 120) {
                    d = 2;
                } 
                else {
                    d = 1;              
                }

                ctx.beginPath();
                ctx.arc(points[0].x, points[0].y, d, 0, 2 * Math.PI, true);
                ctx.fillStyle = '#000000';
                ctx.fill();
            }
            this.points = [];
        },

        drawLine: function() {
            var points = this.points,
                i = 0;
            this.context.beginPath();
            this.context.moveTo(points[0].x, points[0].y);
            for (i = 1; i < points.length; i++) {
                this.context.lineTo(points[i].x, points[i].y);
                this.context.stroke();
                this.context.moveTo(points[i].x, points[i].y);
            }
            this.points = [];
        },

        drawCurvyLine: function() {
            var ctx = this.context,
                points = this.points,
                i = 0,
                numPoints = 3,
                midX = 0,
                midY = 0;

            if (points.length < numPoints) return;
            this.lineWeightCheck();
            ctx.beginPath();
            ctx.moveTo(points[0].x, points[0].y);
            // loop through the points
            for (i = 0; i < points.length - 1; i++) {
                // easeing - move less than you might have
                points[i + 1].x = points[i].x + ((points[i + 1].x - points[i].x) * this.ease);
                points[i + 1].y = points[i].y + ((points[i + 1].y - points[i].y) * this.ease);
                midX = (points[i].x + points[i + 1].x) / 2;
                midY = (points[i].y + points[i + 1].y) / 2;
                ctx.quadraticCurveTo(points[i].x, points[i].y, midX, midY);
            }
            ctx.stroke();
            // start next time where we left off from the midpoint of the last two, 
            // with the last one still relevant to the next midpoint
            this.points = [{'x':midX,'y':midY},points.pop()];
        },
        
        clearCanvas: function() {
            this.context = this.canvas.getContext('2d');
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            if (this.options.callback) {
                this.options.callback('clear');
            }
        }

    });

}( jQuery ));